.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 284px;
  margin: auto auto 16px;

  @media screen and (max-width: 980px) {
    margin: auto auto 12px;
  }

  p,
  a {
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #dbdbdb;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.7;
    }
  }
  &__bottom-team {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #dbdbdb;
    margin-bottom: 28px;

    a {
      color: #3c8618;
      transition: all 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
