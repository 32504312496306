@import url("https://api.fontshare.com/v2/css?f[]=array@400&display=swap");

body {
  background: linear-gradient(
      169.65deg,
      rgba(40, 90, 16, 0.4) -7.21%,
      rgba(25, 86, 100, 0.4) 61.19%,
      rgba(56, 25, 100, 0.4) 112.19%
    ),
    #1d1d1d;

    @media screen and (max-width: 980px) {
      height: 100%;
      width: 100%;
    }
}

#root > section.ant-layout {
  background: rgba(0, 0, 0, 0) !important;
}

#root{
  @media screen and (max-width: 980px) {
    height: 100%;
  }
}

section{
@media screen and (max-width: 980px) {
  height: 100%;
}
}

.main{
  flex: none;
  @media screen and (max-width: 980px) {
    height: 100%;
  }
}
