.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 88px);
  position: relative;
  background: linear-gradient(
      169.65deg,
      rgba(40, 90, 16, 0.4) -7.21%,
      rgba(25, 86, 100, 0.4) 61.19%,
      rgba(56, 25, 100, 0.4) 112.19%
    ),
    #1d1d1d !important;

  .unlock-model {
    margin-left: 16px;
    color: #49aa19 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer !important;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.7;
    }
    .anticon {
      margin-right: 2px;
    }
  }

  .locked-radio {
    span:nth-of-type(2) {
      display: flex;
      align-items: center;
    }
  }

  &__back {
    position: absolute;
    left: 8%;
    top: 46px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3c8618 !important;
    transition: all 0.3s ease-in;

    span {
      margin-right: 8px;
    }
    @media screen and (max-width: 980px) {
      left: 20px;
      top: 27px;
      span {
        &:nth-of-type(n + 2) {
          display: none;
        }
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 980px) {
    height: fit-content;
    min-height: auto;
  }

  &__user-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #3c8618;
      display: flex;
      transition: all 0.3s ease-in;

      span {
        position: relative;
        right: 0;
        margin-right: 8px;
        top: 0;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__input-user {
    @media screen and (max-width: 980px) {
      margin-bottom: 60px !important;
    }
    button.ant-btn-default {
      display: flex;
      width: 100%;
      max-width: none;
      justify-content: center;
      align-items: center;
      margin: 8px 0 0;

      span {
        position: relative;
        right: 0;
        top: 0;
      }
    }
    div {
      button.ant-btn-default {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0;
        margin: 0;
        box-shadow: none;
        span {
          position: relative;
          right: 0;
          top: 0;
          color: #e84749;
        }
        div {
          display: none !important;
        }
        border: inherit !important;
        outline: inherit !important;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 413px;
    margin: 40px 0 0;
    display: flex;
    flex-direction: column;

    button {
      margin: 0px auto;
    }
    @media screen and (max-width: 980px) {
      margin-top: 20px;
    }

    h1 {
      font-family: "Array";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #dbdbdb;
      margin-bottom: 40px;
      @media screen and (max-width: 980px) {
        margin-top: 0px;
      }
    }
  }

  &__score-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media screen and (max-width: 980px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__box {
    width: 50%;
    max-width: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 980px) {
      width: 100%;

      &:nth-of-type(n + 2) {
        margin-top: 32px;
      }
    }
  }

  &__box-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #dbdbdb;
    margin: 0 0 4px;
  }

  &__box-number {
    font-family: "Array";
    font-style: normal;
    font-weight: 500;
    font-size: 72px;
    line-height: 72px;
    text-align: center;
    margin: 0;
    color: #dbdbdb;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #dbdbdb;
    margin: 0 0 20px;
  }

  &__input {
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    position: relative;

    &.profile__input-text {
      span {
        position: absolute;
        right: 12px;
        top: 39px;
      }
    }

    .anticon-close-circle {
      color: #d32029;
    }

    &:nth-of-type(n + 1) {
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #dbdbdb;
      margin: 0 0 8px;
      &.profile__input-note {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        margin: 0;
        color: #7d7d7d;
      }
    }
  }

  &__input-invalid {
    border: 1px solid #d32029 !important;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: -4px auto 16px;
    max-width: 335px;
    column-gap: 8px;
  }

  button {
    width: 50%;
    max-width: 181px;
  }

  .ant-btn-default {
    background: inherit;
    border: 1px solid #3c8618;
    color: #3c8618;

    &:hover {
      opacity: 0.7;
    }
  }

  &__logout {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    right: 120px;
    top: 52px;
    color: #3c8618;
    text-align: center;
    transition: all 0.3s ease-in;
    cursor: pointer;
    span {
      margin-right: 8px;
    }
    @media screen and (max-width: 980px) {
      right: 20px;
      top: 27px;
      .text-logout {
        display: none;
      }
      span {
        margin-right: 0px;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
