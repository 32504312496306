.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  height: 100vh;

  @media screen and (max-width: 980px) {
    padding-top: 28px;
    height: 100%;
  }

  &__comment-links {
    position: absolute;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    @media screen and (max-width: 980px) {
      height: 100%;
    }
  }

  &__comment-link {
    position: absolute;
    top: 0;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.6;
    }

    &:nth-of-type(1) {
      top: 126px;
      left: 120px;
    }
    &:nth-of-type(2) {
      top: 252px;
      left: 60px;
      animation-delay: 8s;
    }
    &:nth-of-type(3) {
      top: 394px;
      left: 60px;
      animation-delay: 16s;
    }
    &:nth-of-type(4) {
      top: 520px;
      left: 120px;
      animation-delay: 24s;
    }
    &:nth-of-type(5) {
      top: 126px;
      right: 120px;
      animation-delay: 32s;
    }
    &:nth-of-type(6) {
      top: 268px;
      right: 50px;
      animation-delay: 40s;
    }
    &:nth-of-type(7) {
      top: 394px;
      right: 60px;
      animation-delay: 48s;
    }
    &:nth-of-type(8) {
      top: 536px;
      right: 111px;
      animation-delay: 56s;
    }
    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    text-align: center;
    font-family: "Array", sans-serif;

    @media screen and (max-width: 980px) {
      margin-bottom: 16px;
    }
  }

  &__img {
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 1;
    max-width: 512px;
    z-index: 10;
    animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -moz-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');

    @media screen and (max-width: 980px) {
      width: 512px;
      left: -5%;
      top: 20px;
    }
    @media screen and (max-width: 420px) {
      left: -20%;
    }

    &_2 {
      z-index: 9;
      top: 70px;
      animation: float 6s 0.3s ease-in-out infinite, blur 2s ease 0s forwards;
      -webkit-animation: float 6s 0.3s ease-in-out infinite,
        blur 2s ease 0s forwards;
      -moz-animation: float 6s 0.3s ease-in-out infinite,
        blur 2s ease 0s forwards;
      -webkit-filter: blur(50px);
      -moz-filter: blur(50px);
      -o-filter: blur(50px);
      -ms-filter: blur(50px);
      filter: blur(50px);
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
      @media screen and (max-width: 980px) {
        top: 90px;
      }
    }

    &_3 {
      top: 75px;
      animation: float 6s 0.5s ease-in-out infinite, blur 2s ease 0s forwards;
      -webkit-animation: float 6s 0.5s ease-in-out infinite,
        blur 2s ease 0s forwards;
      -moz-animation: float 6s 0.5s ease-in-out infinite,
        blur 2s ease 0s forwards;
      @media screen and (max-width: 980px) {
        top: 70px;
      }
    }
  }

  @keyframes blur {
    100% {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
    }
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .Typewriter {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    font-size: 20px;
    font-family: "Array", sans-serif;
    text-align: center;
    margin: -60px auto 0;
    line-height: 28px;
    max-width: 588px;
    position: relative;
    z-index: 10;
    text-shadow: 0 0 calc(4px) #99cbb2;
    @media screen and (max-width: 980px) {
      padding: 0 20px;
      margin-top: -80px;
    }
  }

  .block-third {
    position: relative;
    width: 100%;

    @media screen and (max-width: 980px) {
      height: 112px;

      &_bg {
        position: absolute;
        width: 90%;
        bottom: 0;
        height: 120%;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        filter: blur(5px);
        z-index: 10;
        margin: 0 auto;
        left: 20px;
        right: auto;
        border-radius: 6px;
      }
      .Typewriter {
        background: none;
        position: absolute;
        z-index: 10;
        width: 100%;
        font-size: 19px;
        line-height: 22px;
        padding: 0 25px;
        bottom: 0;
      }
    }
  }
  .login-form {
    max-width: 335px;
    width: 100%;
    position: relative;
    margin-top: -80px;
    z-index: 100;
    margin-bottom: 68px;

    @media screen and (max-width: 980px) {
      margin-bottom: 60px;
      margin-top: -120px;
    }

    .ant-form-item-explain-error {
      color: #7d7d7d;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
    }
  }
  .ant-form-item:nth-of-type(2) {
    margin: 0;
  }
  .login-form-button {
    width: 100%;
  }

  &__img-box {
    position: relative;
    margin-top: -100px;
    height: 582px;
    width: 100%;
    max-width: 612px;
    overflow: hidden;
    @media screen and (max-width: 980px) {
      height: 620px;
    }
  }

  .next-button {
    width: 100%;
    max-width: 355px;
    position: relative;
    z-index: 100;
    margin-top: 20px;

    @media screen and (max-width: 980px) {
      margin-bottom: 60px;
      max-width: 335px;
    }
  }

  &__text {
    margin-top: 45px;
    text-align: center;
    display: flex;
    font-size: 20px;
    line-height: 28px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 284px;
    margin: 68px auto 16px;

    @media screen and (max-width: 980px) {
      margin: 30px auto 12px;
    }

    p {
      font-weight: 400;
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: #dbdbdb;
      cursor: pointer;
      transition: all 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__bottom-team {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #dbdbdb;
  }
}
