.ant-modal-body {
  a {
    color: #3c8618;

    &:hover {
      opacity: 0.7;
    }
  }

  h3 {
    font-family: "Array";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #dbdbdb;
    justify-content: center;
    margin: 12px auto;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
    color: #dbdbdb;
    @media screen and (max-width: 980px) {
      max-width: 279px;
    }
  }
}

.clues {
  &__box {
    display: flex;
    align-items: center;
    margin: 20px auto 25px;
    justify-content: center;
  }

  &__clue {
    width: 30%;
    max-width: 137.33px;
    border: 1px solid #1d3712;
    border-radius: 8px;
    padding: 16px 8px 8px;

    img {
      width: 100%;
      max-width: 64px;
      display: block;
      margin: 0 auto 8px;
    }
    @media screen and (max-width: 980px) {
      width: 35%;
      img {
        max-width: 48px;
      }
    }

    h4 {
      font-family: "Array";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #dbdbdb;
      margin: 0;

      span {
        font-size: 28px;
        line-height: 28px;
      }
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 32px;
      transition: all 0.3s ease-in;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      margin: 0;
      border-radius: 6px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #3c8618;
      margin: 8px auto 8px;
    }

    &_big {
      padding: 16px 8px 16px;
      border: 1px solid #306317;
      margin: 0 12px;
      img {
        margin-bottom: 16px;
      }

      p {
        margin: 8px auto 16px;
      }
      @media screen and (max-width: 980px) {
        margin: 0 4px;
      }
    }
  }
}

.ant-modal-content {
  top: 25px;
  @media screen and (max-width: 980px) {
    padding: 20px 16px !important;
  }
}
