.header {
  height: 78px;
  background: #141414;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 25px 120px;
  @media screen and (max-width: 1280px) {
    padding: 25px 60px;
  }
  &__score {
    font-family: "Array", sans-serif;
    font-size: 24px;
    line-height: 24px;
    margin: 0 auto;
    color: #fff !important;
  }
  &__logo {
    margin: 0 53px 0 0;

    &_small {
      margin: 0 24px 0 0;
      img {
        position: relative;
        top: -1.5px;
        height: 31px;
      }
      @media screen and (max-width: 980px) {
        display: none !important;
      }
    }
  }

  &__login {
    color: #3c8618 !important;
    transition: all 0.3s ease-in;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 24px;

    .anticon-login {
      margin-right: 9px;
      @media screen and (max-width: 980px) {
        margin: 0;
      }
    }
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 980px) {
      position: absolute;
      right: 20px;
      top: 30px;
      margin: 0;

      span {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
  }

  &__note {
    margin: 0 24px 0 auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #3c8618;
    @media screen and (max-width: 1250px) {
      margin: 0 14px 0 auto;
      width: 30%;
    }

    @media screen and (max-width: 1140px) {
      width: 20%;
    }

    @media screen and (max-width: 1000px) {
      width: 18%;
    }
  }

  &__signup {
    background: #3c8618;
    border-radius: 6px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff !important;
    transition: all 0.3s ease-in;
    &:hover {
      opacity: 0.7;
    }
  }

  &__btn {
    margin-left: 43px;
    color: #3c8618;

    .anticon {
      margin-right: 8px;
    }

    &_number {
      font-weight: 700;
    }

    &:hover {
      color: #5c9d3b;
    }
  }

  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
    height: 122px;
    padding: 8px 22px 16px;
    position: relative;

    &_short {
      height: 40px;
      padding: 8px 20px;

      .header__logo {
        img {
          margin: 0 auto;
        }
      }
    }

    &__note {
      display: none;
    }

    &__signup {
      padding: 1px 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      position: absolute;
      margin: 0;
      left: 20px;
      top: 30px;
      width: 68px;
    }

    &__logo {
      // width: calc(100% - 36px);
      width: 100%;
      margin: 0 auto;

      &_loading{
        display: block !important;
      }

      img {
        width: 100%;
        max-width: 110px;
        margin: 25px auto;
        display: block;
      }
    }

    &__small {
      .header__logo {
        width: 80%;
      }
    }

    &_hidden {
      .header__logo {
       display: none;
      }
    }


    .ant-select {
      width: 100% !important;
      margin: auto 0 0 0;
    }

    &__btn {
      margin: 0;
      flex-direction: row-reverse;
      display: flex;
      line-height: 22px;
      .anticon {
        margin: 0 0 0 5px;
        font-size: 18px;
      }
      &_title,
      &_number {
        display: none;
      }

      &_leaderboard {
        margin: 0;
        order: -1;
      }
    }

    .ant-select {
      order: 4;
    }
  }
}

.option {
  &__items {
    display: block;
    width: 40%;
    text-align: end;
    font-family: "Array", sans-serif;

    @media screen and (max-width: 980px) {
      font-size: 12px;
      width: 16%;
      line-height: 20px;
    }
  }
}

.ant-select-dropdown {
  padding: 4px 0;
  width: 100% !important;
  max-width: 384px;
  @media screen and (max-width: 980px) {
    max-width: inherit;
    width: calc(100% - 44px) !important;
  }
  .ant-select-item {
    border-radius: 0;
    .ant-select-item-option-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: none;
      justify-content: space-between;
      align-items: center;
    }

    .ant-space {
      width: 110%;
    }
    .option__items {
      .anticon-check-circle,
      .anticon-trophy,
      .anticon-message,
      .anticon-bulb {
        color: #3c8618;
        margin-right: 5.75px;
        margin-left: 9px;
      }
      @media screen and (max-width: 980px) {
        .anticon-trophy {
          display: inline;
        }
      }
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #2f2e2e9e;
  }
}

.anticon-check-circle,
.anticon-trophy {
  color: #3c8618;
}
