.not-found-page {
  height: 92vh;
}

.not-found {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  max-height: 70vh;

  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8C8C8C;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  &__button {
    width: 173px;
    height: 40px;
    background: #2F54EB;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;

    &:hover{
      color: #FFFFFF;
      background: #1677ff;
    }
  }

  &__not-found-icon {

    svg {
      width: 100%;
      max-width: 190px;
      height: 100%;
      max-height: 120px;
      color: transparent;
    }
  }
}
