.login {
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 980px) {
    height: 100%;
  }
  &__link {
    color: #3c8618;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      color: #3c8618;
      opacity: 0.7;
    }
  }
  .login-box {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    h1 {
      font-family: "Array";
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 20px;
      color: #dbdbdb;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      margin-bottom: 40px;
      color: #dbdbdb;
      @media screen and (max-width: 980px) {
        padding: 0 20px;
      }
    }

    button {
      width: 100%;
      height: 40px;
    }
  }

  &__form {
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
  }

  &__form-item_name {
    margin-bottom: 16px;
  }

  &__action {
    margin-top: 40px;

    span {
      color: #3c8618;
      cursor: pointer;
      transition: all 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .ant-form-item-explain-error {
    margin-bottom: 10px;
  }

  .social-btn {
    border-radius: 6px;
    border: 1px solid #dbdbdb;
    max-width: 335px;
    margin: 12px auto 0;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.7;
    }
  }
}
