.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 88px);
  position: relative;
  width: 100%;
  background: linear-gradient(
      169.65deg,
      rgba(40, 90, 16, 0.4) -7.21%,
      rgba(25, 86, 100, 0.4) 61.19%,
      rgba(56, 25, 100, 0.4) 112.19%
    ),
    #1d1d1d;

  @media screen and (max-width: 980px) {
    height: 100vh;
  }

  h1 {
    font-family: "Array";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #dbdbdb;
    margin: 40px auto 40px;
    @media screen and (max-width: 980px) {
      margin: 20px auto 24px;
    }
  }

  &__box {
    height: 100%;
    width: 100%;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: hidden;
  }

  &__table {
    width: 100%;
    margin: 0 auto;
    max-width: 620px;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 980px) {
      height: 87%;
    }
  }

  &__back {
    position: absolute;
    left: 8%;
    top: 46px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3c8618 !important;
    transition: all 0.3s ease-in;

    span {
      margin-right: 8px;
    }
    @media screen and (max-width: 980px) {
      left: 20px;
      top: 27px;
      span {
        &:nth-of-type(n + 2) {
          display: none;
        }
      }
    }
  }

  &__table-first-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 16px;
  }

  &__table-box {
    width: 35%;
    max-width: 190.67px;
    padding: 0 14px 14px;
    background: #141414;
    border: 1px solid #434343;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(2) {
      margin: 0 8px 0;
    }

    @media screen and (max-width: 980px) {
      &:nth-of-type(2) {
        margin: 0 2px 0;
      }
    }

    p {
      color: #ffffff;
      font-size: 12px;
      line-height: 20px;
      text-align: center;

      &.user {
        font-weight: 700;
        margin: 1px 0 0;
      }

      &.score {
        font-weight: 600;
        margin: 0px 0 3px;
        font-family: "Array", sans-serif;
        color: #ffffff;
      }
    }
  }

  &__medal {
    font-weight: 400;
    font-size: 54px;
    line-height: 64px;
    text-align: center;
    position: relative;
    top: -4.5px;
  }

  &__table-row {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #303030;

    p {
      color: #ffffff;

      &.place {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        width: 20%;
        max-width: 54px;
        margin: 0;
      }

      &.name {
        width: 80%;
        max-width: 484px;
        margin: 0 8px 0;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;

        a{
          color: #3c8618;
        }
      }

      &.score {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: right;
        width: 20%;
        font-family: "Array", sans-serif;
        max-width: 50px;
        margin: 0;
      }
    }
  }

  .table-row__current {
    background: rgba(60, 134, 24, 0.3);
    border: 1px solid #3c8618;
    border-radius: 6px;
    padding: 10px 16px;

    &_fixed {
      width: 100%;
      height: 54px;
      @media screen and (max-width: 980px) {
        padding: 0 4px;
      }
    }

    &_fix {
      position: fixed;
      bottom: 20px;
      left: auto;
      width: 100%;
      max-width: 620px;
      backdrop-filter: blur(5px);
      right: auto;
      margin: 0 auto;

      &_top {
        top: 180px;
        bottom: auto;

        @media screen and (max-width: 980px) {
          top: 120px;
        }
      }
      @media screen and (max-width: 980px) {
        width: 98%;
      }
    }
  }

  &__show-more {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #3c8618;
    transition: all 0.3s ease-in;
    cursor: pointer;
    margin: 16px auto;
    text-align: center;

    &:hover {
      opacity: 0.7;
    }
  }
}

.ant-popover-title {
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 22px;
  min-width: 140px !important;
}
