.loading {
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      169.65deg,
      rgba(40, 90, 16, 0.4) -7.21%,
      rgba(25, 86, 100, 0.4) 61.19%,
      rgba(56, 25, 100, 0.4) 112.19%
    ),
    #1d1d1d !important;

  @media screen and (max-width: 980px) {
    height: calc(100% - 40px);
  }

  &.loading-no-bg {
    background: none !important;
  }
  .ant-spin {
    margin: auto 0;
  }
}
