.body {
  min-height: calc(100vh - 78px);
  background-size: cover !important;
  max-height: calc(100vh - 78px);
  overflow: hidden;

  @media screen and (max-width: 980px) {
    max-height: inherit;
    height: calc(100% - 122px);
    min-height: inherit;
    overflow: hidden;
    flex: none;
  }

  &__main-download {
    position: absolute;
    right: 40px;
    top: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #dbdbdb;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.6;
    }

    &_show {
      right: 210px;
    }

    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  &_bg {
    background: linear-gradient(
      169.65deg,
      rgba(40, 90, 16, 0.4) -7.21%,
      rgba(25, 86, 100, 0.4) 61.19%,
      rgba(56, 25, 100, 0.4) 112.19%,
      #1d1d1d
    ) !important;
  }

  &__main {
    height: calc(100vh - 218px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    flex-direction: column;
    padding: 0px;

    .confetti {
      top: 75px !important;
      height: 100% !important;
    }

    &_win {
      height: calc(100vh - 198px);
    }

    &_no-bg {
      background: none !important;
    }

    @media screen and (max-width: 980px) {
      padding: 0 20px 35px;
      height: calc(100% - 54px);
      &_win {
        padding-bottom: 0;
        .body {
          height: calc(100% - 140px);
        }
      }
    }

    h3 {
      font-family: "Array", sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    h4 {
      font-family: "Array", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0 !important;
    }
  }

  &__bottom-about {
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    right: 40px;
    bottom: 40px;
    color: #fff;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.7;
    }

    @media screen and (max-width: 980px) {
      position: relative;
      right: auto;
      bottom: auto;
      border: inherit;
      width: 24px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 0 auto;
    }
  }

  &__message {
    padding: 16px 24px;
    background: #141414;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #dbdbdb;
    margin: 10px 0 10px;
    max-width: 486px;
    width: fit-content;
    p {
      margin-bottom: 0;
    }
    @media screen and (max-width: 980px) {
      max-width: 292px;
    }

    &_loading {
      display: flex;
      width: 200px;
      flex-direction: column;

      .dot-box {
        display: flex;
        margin-top: 4px;
      }
    }
  }

  &__message-clue {
    background: #2b2111;
    border: 1px solid #aa7714;
    animation: clue 1s 3s ease-in forwards;

    @keyframes clue {
      0% {
        background: #2b2111;
      }
      100% {
        background: #141414;
        border: 1px solid #141414;
      }
    }
  }

  .dot-flashing {
    position: relative;
    top: 12px;
    left: 13px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #dbdbdb;
    color: #dbdbdb;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -8px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #dbdbdb;
    color: #dbdbdb;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 8px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #dbdbdb;
    color: #dbdbdb;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #3c8618;
    }
    50%,
    100% {
      background-color: rgba(210, 208, 217, 0.2);
    }
  }

  &__message_action {
    margin-top: 10px;
    margin-bottom: 5px;
    max-width: 509px;
    @media screen and (max-width: 980px) {
      max-width: 292px;
    }
  }

  &__message_action-text {
    background: #141414;
    border-radius: 6px 6px 0px 0px;
    padding: 16px 24px;
    border-bottom: solid 1px #434343;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    color: #dbdbdb;
  }

  &__message_action-btn {
    background: #141414;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3c8618;
    transition: all 0.3s ease-in;
    cursor: pointer;
    border-radius: 0px 0px 6px 6px;

    span {
      margin-right: 10.5px;
    }

    &:hover {
      color: #5c9d3b;
    }
  }

  &__message-user {
    text-align: start;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #dbdbdb;
    width: fit-content;
    margin: 10px 0 0 auto;
    max-width: 486px;

    @media screen and (max-width: 980px) {
      max-width: 292px;
    }

    &_text {
      background: #00200a;
      border-radius: 6px;
      padding: 16px 24px;
      margin-bottom: 4px;

      p {
        margin-bottom: 0;
      }
    }

    &_bottom {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #acacac;

      span {
        margin-right: 4.7px;
      }
    }
  }

  &__main-box {
    max-width: 588px;
    width: 100%;
    position: relative;
    max-height: 100%;
    overflow-y: scroll;
    top: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    transition: all 0.5s ease-in;

    &_hide {
      top: -100%;
    }

    h3 {
      margin-top: 10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      width: 100%;
      margin-top: 10px;
      height: 40px;
    }
  }

  &__message-ai_wrong {
    border: 1px solid #141414;

    &:last-of-type {
      background: #431418;
      border: 1px solid #a61d24;
      animation: wrong 1s 3s ease-in forwards;
    }

    p {
      margin: 0;
    }

    @keyframes wrong {
      0% {
        background: #431418;
      }
      100% {
        background: #141414;
        border: 1px solid #141414;
      }
    }
  }

  &__message-ai_correct {
    border: 1px solid #3c8618;
    background: #1d3712;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }

  &__bottom {
    min-height: 140px;
    background: rgba(13, 18, 11, 0.8);
    padding: 20px 40px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease-in;

    &_win {
      height: auto;
      min-height: auto;
      margin: auto 0 0;
      padding-top: 0;
      background: none;
    }

    @media screen and (max-width: 980px) {
      padding: 12px 20px;
      min-height: inherit;
      height: fit-content;
    }
  }

  &__bottom-hide {
    animation: hideBottom 0.3s ease-in forwards;
  }

  @keyframes hideBottom {
    20% {
      top: 20px;
      opacity: 0.9;
    }
    50% {
      top: 30px;
      opacity: 0.5;
    }
    99% {
      height: 120px;
      min-height: 120px;
    }
    100% {
      top: 40px;
      opacity: 0;
      height: 0px;
      min-height: 0px;
      padding: 0;
    }
  }

  &__bottom-box {
    max-width: 588px;
    width: 100%;
    @media screen and (max-width: 980px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: fit-content;

      textarea {
        max-width: 85%;
        padding-left: 0;
        font-size: 16px;
        line-height: 22px;
        border: inherit;
        box-shadow: inherit !important;
        background: rgba(13, 18, 11, 0) !important;
      }
    }
  }

  &__bottom-container {
    width: calc(100% - 65px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 980px) {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      padding: 0 20px;
    }
  }

  &__bottom-panel {
    display: flex;
    align-items: center;
    margin-top: 16px;

    p {
      margin-bottom: 0;
    }

    button {
      height: 40px;
    }

    @media screen and (max-width: 980px) {
      margin: 0;

      button {
        background-color: inherit !important;
        border: none;
        box-shadow: none;
        color: #5c9d3b;
        display: none;

        &.display-send {
          display: block;
        }
        @media screen and (max-width: 980px) {
          padding: 0;
          position: absolute;
          top: 8px;
          right: 20px;
        }
      }
    }
  }

  &__bottom-symbols {
    margin: 0 0 0 17.25px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #dbdbdb;
    width: 70%;

    span {
      margin-right: 9.25px;
      &:nth-of-type(3) {
        color: #6d6a6a;
      }
    }

    .symbols {
      color: #dbdbdb;
      margin: 0;
    }

    @media screen and (max-width: 980px) {
      width: 50%;
      margin: 0;
      span {
        margin-right: 5px;
      }

      .symbols {
        display: none;
      }
    }
  }

  &__bottom-hint {
    margin: 0 0 0 auto;
    font-weight: 600;
    color: #3c8618;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s ease-in;
    cursor: pointer;
    width: 41%;
    text-align: end;

    &.pulse-animation {
      -webkit-animation: pulse 8s linear infinite;
      animation: pulse 8s linear infinite;
    }
    @keyframes pulse {
      0% {
        color: #3c8618;
      }
      50% {
        color: #55ff00;
        transform: scale(1.05);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
      }
      100% {
        transform: scale(1);
        color: #3c8618;
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
      }
    }

    span {
      margin-right: 7.59px;
    }

    @media screen and (max-width: 980px) {
      position: absolute;
      top: 45px;
      right: 60px;
      width: 24px;
      display: none;
      .clue {
        display: none;
      }
      span {
        margin: 0;
        font-size: 24px;
      }
      &.display-clue {
        display: block;
        height: 24px;
        &.display-clue_right {
          right: 20px;
        }
      }
      &.display-clue_start {
        display: block;
        right: 20px;
      }
    }

    .clue {
      margin: 0;
    }

    &:hover {
      color: #5c9d3b;
    }

    &_disabled {
      color: #5a5a5a !important;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__bottom-win {
    width: 100%;
    max-width: 588px;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 980px) {
      max-width: inherit;
      margin: 0 auto;
    }

    button {
      width: 50%;
      max-width: 288px;
      height: 40px;

      @media screen and (max-width: 980px) {
        max-width: inherit;
        width: 48%;
      }

      &:nth-of-type(2) {
        color: #5c9d3b;
        background: inherit;
        border-color: #5c9d3b;
      }
    }
  }

  &__message_results-score {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 980px) {
      margin: 0 0 0 auto;
    }

    .score {
      text-align: center;
      font-size: 48px;
      font-family: Array;
      margin: 0;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      color: #6abe39;
    }

    .score-info {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      line-height: 20px;
      margin: 6px auto 20px;
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
    }

    .total-score {
      color: #fff;
      text-align: center;
      font-size: 24px;
      font-family: Array;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .share-link {
      width: 100%;
      @media screen and (max-width: 980px) {
        position: absolute;
        bottom: 20px;
        left: 12px;
        width: 93%;
        max-width: none;
      }
    }

    .twitter-link {
      display: flex;
      max-width: 243px;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 6px;
      background: #1765ad;
      color: #fff;
      width: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 4px auto 0;
      transition: all 0.3s ease-in;
      @media screen and (max-width: 980px) {
        max-width: none;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__message_results-imgs {
    width: 100%;
    margin: 0;
    max-width: 284px;
    height: 284px;
    width: 50%;
    position: relative;
    img {
      width: 100%;
    }
    @media screen and (max-width: 980px) {
      height: 159px;
      width: 53%;
    }
  }

  &__message_results-img {
    position: relative;
    z-index: 10;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    animation:  blur 2s ease 0s forwards;
    -webkit-animation: blur 2s ease 0s forwards;
    -moz-animation:  blur 2s ease 0s forwards;
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');

    &_top{
      z-index: 0;
    }

    &_animated {
      animation: float 6s 1s ease-in-out infinite, blur 2s ease 0s forwards;
      -webkit-animation: float 6s 1s ease-in-out infinite,
        blur 2s ease 0s forwards;
      -moz-animation: float 6s 1s ease-in-out infinite, blur 2s ease 0s forwards;
    }
    @media screen and (max-width: 980px) {
      max-width: 159px;
      margin: 0 auto;
      display: block;
    }
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  &__message_results-img_bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -moz-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
    @media screen and (max-width: 980px) {
      max-width: 159px;
      right: 0;
      margin: 0 auto;
    }
  }

  &__message_results-img_bg_second {
    animation: float 6s 3s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s 3s ease-in-out infinite, blur 2s ease 0s forwards;
    -moz-animation: float 6s 3s ease-in-out infinite, blur 2s ease 0s forwards;
  }

  &__message_results-img_bg_third {
    z-index: 100;
    animation: float 6s 4s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s 4s ease-in-out infinite, blur 2s ease 0s forwards;
    -moz-animation: float 6s 4s ease-in-out infinite, blur 2s ease 0s forwards;
  }

  @keyframes blur {
    100% {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
    }
  }

  &__message_results {
    width: 100%;
    background: #141414;
    border-radius: 6px;
    padding: 24px 10px 10px;
    margin: 10px 0 0;

    @media screen and (max-width: 980px) {
      margin-bottom: 4px;
      height: 289px;
      position: relative;
      padding: 16px 12px 20px;
    }

    h4 {
      font-family: "Array";
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #dbdbdb;
      margin: 0 0 10px !important;
      @media screen and (max-width: 980px) {
        font-size: 22px;
      }
    }
  }

  &__message_results-big {
    @media screen and (max-width: 980px) {
      height: 329px;
    }
  }

  &__message_results-box {
    display: flex;
  }

  &__message_results-line {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &:last-of-type {
      margin: 0;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #dbdbdb;

      span {
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
          opacity: 0.7;
        }
      }
      &.total {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      &.red {
        color: #f37370;
      }
    }
  }

  .leaderboard-place {
    margin: 24px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #dbdbdb;
  }

  .signup {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    color: #dbdbdb;
    margin: 16px auto 0;
    @media screen and (max-width: 980px) {
      margin: 20px auto 0;
    }

    a {
      color: #3c8618;
      transition: all 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__message_results-share {
    padding: 8px 0 0;
    border-top: 1px solid #434343;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #3c8618;
    transition: all 0.3s ease-in;
    margin-top: 24px;

    a {
      color: #3c8618;
      transition: all 0.3s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 980px) {
      margin-top: 9px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .anticon-send {
    display: none;
    font-size: 24px;
  }

  @media screen and (max-width: 980px) {
    .btn-send {
      display: none;
    }

    .anticon-send {
      display: block;
    }
  }

  &__finish-container {
    position: relative;
    max-width: 588px;
    width: 100%;
    top: 10%;
    min-height: calc(100vh - 147px);
    margin: 0 auto 0;
    @media screen and (max-width: 980px) {
      min-height: calc(100vh - 222px);
      top: 0%;
    }

    .hide_coursor {
      .Typewriter__cursor {
        display: none !important;
      }
    }

    .body__message_action-btn {
      max-width: 335px;
      height: 40px;
      background: #367517;
      border-radius: 6px;
      color: #fff;
      position: relative;
      z-index: 20;
      margin: 24px auto 0;
      transition: all 0.3s ease-in;

      &:hover {
        background-color: #4f872f;
      }
    }
  }

  &__img-box {
    position: relative;
    margin: -140px auto 0;
    height: 582px;
    max-width: 512px;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 980px) {
      height: 400px;
      margin-top: 0px;
    }
  }

  .Typewriter {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 500;
    font-size: 32px;
    font-family: "Array", sans-serif;
    text-align: center;
    margin: -125px auto 0;
    line-height: 40px;
    max-width: 588px;
    position: relative;
    z-index: 10;
    text-shadow: 0 0 calc(4px) #99cbb2;
    @media screen and (max-width: 980px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .step-2 {
    .Typewriter {
      font-size: 20px;
      line-height: 28px;
      margin: 0 auto;

      &:nth-of-type(2) {
        margin-top: -120px;
        @media screen and (max-width: 460px) {
          margin-top: -155px;
        }
      }
    }
  }

  &__img {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 1;
    max-width: 512px;
    z-index: 10;
    animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -moz-animation: float 6s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
    @media screen and (max-width: 460px) {
      top: 45px;
    }
  }

  &_2 {
    z-index: 9;
    top: 70px;
    animation: float 6s 0.3s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-animation: float 6s 0.3s ease-in-out infinite,
      blur 2s ease 0s forwards;
    -moz-animation: float 6s 0.3s ease-in-out infinite, blur 2s ease 0s forwards;
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
    @media screen and (max-width: 980px) {
      top: 90px;
    }
  }

  @keyframes blur {
    100% {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
    }
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  .hide-chat {
    opacity: 0;
  }
}
