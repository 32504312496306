.ant-modal-body {
  a {
    color: #3c8618;
    transition: all 0.3s ease-in;

    &:hover {
      opacity: 0.7;
    }
  }
}

.ant-modal.privacy {
  width: 100% !important;
  max-width: 800px;

  @media screen and (max-width: 980px) {
    width: 93% !important;
  }
}

.ant-modal-content {
  top: 25px;
}

.about {
  .ant-modal-body p {
    text-align: start;
    margin: 0;
  }
}

.privacy {
  .ant-modal-body p {
    text-align: start;
    margin: 0;
  }
}
